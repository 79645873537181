<template>
  <div>
    <b-modal
      id="edit-level-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="edit-level-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('edit-level-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Update Level</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="editLevel" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="image-upload w-100">
              <label for="level-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="levelImage"
                id="level-image-input"
                class="mt-3"
                ref="levelImageRef"
              />
            </div>
          </div>
          <div class="col-md-4 level-image-preview">
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else-if="levelToEdit != null && levelToEdit.image">
              <img :src="levelToEdit.image" alt="Level image" />
            </template>
            <template
              v-else-if="
                levelToEdit !== null && levelToEdit.image === undefined
              "
            >
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-md-6">
            <label for="baskytorLevel">Baskytor Level</label>
            <b-form-input
              required
              type="number"
              v-model="form.baskytorLevel"
              placeholder="Enter Bakytor Level"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="name">Name</label>
            <b-form-input
              required
              v-model="form.name"
              placeholder="Enter Level Name"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-md-6">
            <label for="orderTarget">Orders Target</label>
            <b-form-input
              required
              type="number"
              v-model="form.orderTarget"
              placeholder="No. of Orders"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="ratingTarget">Rating Target</label>
            <b-form-input
              required
              type="number"
              step="any"
              :min="0"
              :max="5"
              v-model="form.ratingTarget"
              placeholder="Minimum Avg. Rating"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-6">
            <label for="pay">Pay/hr at this level</label>
            <b-form-input
              v-if="region === 'PAKISTAN'"
              required
              type="number"
              v-model="form.pay"
              placeholder="Pay/hr at this level"
            ></b-form-input>
            <b-form-input
              v-if="region === 'US'"
              required
              type="number"
              v-model="form.levelPremium"
              placeholder="Premium at this level"
            ></b-form-input>
          </div>
          <div v-if="region === 'PAKISTAN'" class="col-6">
            <label for="pay">Penalties</label>
            <b-form-input
              required
              type="number"
              v-model="form.penalties"
              placeholder="Max allowed penalties"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-12">
            <label for="description">Description</label>
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="input-row center">
          <b-button
            type="Submit"
            class="create-button"
            :disabled="processingRequest !== ''"
            :class="processingRequest"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { mapState } from "vuex";
export default {
  props: {
    levelToEdit: Object
  },
  data() {
    return {
      form: {
        baskytorLevel: "",
        name: "",
        description: "",
        pay: 100,
        levelPremium: 100,
        ratingTarget: "",
        orderTarget: "",
        image: "",
        penalties: ""
      },
      imageUrl: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
      levelImage: null,
      basedOptions: [
        { text: "Product Based", value: "product-based" },
        { text: "Vendor Based", value: "vendor-based" }
      ]
    };
  },
  mounted() {
    const val = this.levelToEdit;
    this.form.baskytorLevel = val.baskytorLevel;
    this.form.name = val.name;
    this.form.pay = val.pay;
    this.form.levelPremium = val.levelPremium;
    this.form.description = val.description;
    this.form.ratingTarget = val.ratingTarget;
    this.form.orderTarget = val.orderTarget;
    this.form.levelId = val._id;
    this.form.image = val.image;
    this.form.penalties = val.penalties || 0;
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("edit-level-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("edit-level-modal");
      this.$emit("close");
    },
    uploadFile: function() {
      this.levelImage = this.$refs.levelImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.levelImage);
    },
    resetForm: function() {
      const defaultForm = {
        employeeLevel: "",
        name: "",
        description: "",
        pay: "",
        ratingTarget: "",
        orderTarget: ""
      };
      this.imageUrl = null;
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.levelImage = null;

      this.$emit("close");
    },
    editLevel: function(e) {
      e.preventDefault();
      this.processingRequest = "processing";
      const tk = JwtService.getToken();
      this.form.token = `${tk}`;
      ApiService.post("/levels/editLevel", this.form).then(({ data: res }) => {
        if (res.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.error.message;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);

          return;
        }
        if (res.success) {
          if (this.levelImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.levelImage);
            newFormData.append("levelId", this.form.levelId);
            newFormData.append("token", `${tk}`);
            ApiService.post("/levels/uploadImage", newFormData).then(res => {
              if (res.data.success === 0) {
                this.hasError = "d-flex";
                this.processingError = res.data.message;
                this.processingRequest = "";
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
              } else {
                this.processingRequest = "";
                this.$emit("editedLevel");
              }
            });
          } else {
            this.processingRequest = "";
            this.$emit("editedLevel");
          }
        }
      });
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .edit-level-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      .level-image-preview {
        img {
          width: 100%;
          height: auto;
        }
      }
      &.center {
        display: flex;
        justify-content: center;
        .btn-group {
          width: 100%;
          label.btn {
            &:nth-child(1) {
              margin-right: 5px;
            }
            &:nth-child(2) {
              margin-left: 5px;
            }
            border-radius: 0;
            background: #fff;
            padding: 20px 15px;
            &.active {
              color: #fff;
              background: #909090;
            }
          }
        }
      }
      .image-upload {
        label {
          display: flex;
          align-items: center;
          span.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &.attachment {
              display: flex;
              .text {
                max-width: 100px;
                text-align: center;
              }
            }
            i {
              font-size: 32px;
              color: #000;
            }
            padding: 25px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-right: 10px;
            span.text {
              color: #808080;
            }
          }

          margin: 0;
          cursor: pointer;
        }
        input {
          display: none;
        }
      }
    }
  }
}
</style>
