<template>
  <div class="bonuses">
    <h3 class="heading-text">Bonuses</h3>
    <template v-if="fetching">
      <div class="overlay">
        <div class="d-flex">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
    </template>
    <template v-else-if="!fetching && networkError === null">
      <div class="items">
        <b-col
          cols="6"
          v-for="(bonus, index) in allBonuses"
          :key="bonus.updatedAt"
        >
          <SingleBonus
            :archived="false"
            :bonus="bonus"
            @editBonus="editBonus"
            :index="index"
          />
        </b-col>
      </div>
    </template>
    <template v-else-if="!fetching && networkError !== null">
      <div class="d-flex flex-column align-items-center w-100">
        <div class="text-danger text-center display-4 p-4">
          API Error
        </div>
        <div class="text-danger text-center lead pb-4">
          Error while fetchting active bonuses. Please refresh to try again.
        </div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SingleBonus from "@/view/pages/bonus-levels/SingleBonus";
import { mapState, mapGetters } from "vuex";
import { GET_ALL_BONUSES } from "@/core/services/store/bonuses.module";
export default {
  components: {
    SingleBonus
  },
  methods: {
    ...mapGetters(["getAllBonuses"]),
    update: function() {
      this.$store.dispatch(GET_ALL_BONUSES);
    },
    editBonus: function(index) {
      const bonus = this.allBonuses[index];
      this.$emit("editBonus", bonus);
    }
  },
  computed: {
    ...mapState({
      allBonuses: state => {
        return state.bonuses.allBonuses;
      },
      fetching: function(state) {
        return state.bonuses.bonusesFetching;
      },
      networkError: function(state) {
        return state.bonuses.bonusesError;
      }
    })
  },
  mounted() {
    this.$store.dispatch(GET_ALL_BONUSES);
  }
};
</script>

<style lang="scss" scoped>
.bonuses {
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 25px;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
